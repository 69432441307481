<!--生产发货详情-->
<template>
    <div class="productionDeliveryInfo" v-loading="loading">
        <div class="title-wrapper">
            <div class="title-item">
                <span>任务单号</span>
                <p>{{ taskInfo.task_number }}</p>
            </div>
            <div class="title-item">
                <span>客户名称</span>
                <p>{{ taskInfo.customer_name }}</p>
            </div>
            <div class="title-item">
                <span>工程名称</span>
                <p>{{ taskInfo.project_name }}</p>
            </div>
            <div class="title-item">
                <span>施工部位</span>
                <p>{{ taskInfo.place_detail }}</p>
            </div>
            <div class="title-item">
                <span>供货站点</span>
                <p>{{ taskInfo.station_name }}</p>
            </div>
            <div class="title-item">
                <span>标号</span>
                <p>{{ taskInfo.strength_grade_name }}</p>
            </div>
            <div class="title-item">
                <span>附加型号</span>
                <p>{{ taskInfo.special_require_name?taskInfo.special_require_name:'— —' }}</p>
            </div>
            <div class="title-item">
                <span>浇筑方式</span>
                <p>{{ taskInfo.pouring_name }}</p>
            </div>
        </div>
        <div class="tab-wrapper">
            <div
                class="tab-item"
            >
                <span>任务方量(方)</span>
                <strong>{{ taskInfo.quantity }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>累计车次(车)</span>
                <strong>{{ taskInfo.grand_total_vehicle }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>累计发货方量(方)</span>
                <strong>{{ taskInfo.ljfs_fh }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>砼方量(方)</span>
                <strong>{{ taskInfo.concrete_volume }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>砂浆方量(方)</span>
                <strong>{{ taskInfo.mortar_volume }}</strong>
            </div>
            <div
                class="tab-item bg1"
            >
                <span>累计生产方量(方)</span>
                <strong>{{ taskInfo.filled_volume }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>累计签收方量(方)</span>
                <strong>{{ taskInfo.sign_volume }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>剩退砼(方)</span>
                <strong>{{ taskInfo.surplus_volume }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>来自退砼(方)</span>
                <strong>{{ taskInfo.back_volume }}</strong>
            </div>
            <div
                class="tab-item"
            >
                <span>整车转工地(方)</span>
                <strong>{{ taskInfo.turn_volume }}</strong>
            </div>
        </div>
        <div class="record-line-wrapper">
            <div class="record-item-list">
                <div class="record-item item-big">
                    <div class="txt-popover top">
                        <strong>任务单下发</strong>
                        <span>{{ taskInfo.distributed_time }}</span>
                    </div>
                    <div class="icon">
                        <i class="iconfont iconrenwuxiafa"></i>
                    </div>
                </div>
                <div class="record-item item-big">
                    <div class="txt-popover bottom">
                        <strong>关联配合比</strong>
                        <span>{{ taskInfo.formula_time }}</span>
                    </div>
                    <div class="icon">
                        <i class="iconfont iconbiliduidietu"></i>
                    </div>
                </div>
                <div class="record-item item-big">
                    <div class="txt-popover top">
                        <strong>首车派单</strong>
                        <span>{{ taskInfo.first_deliver }}</span>
                    </div>
                    <div class="icon">
                        <i class="iconfont icondenglu"></i>
                    </div>
                </div>
                <div class="record-item item-small">
                    <div class="txt-popover bottom">
                        <strong>首车接单</strong>
                        <span>{{ taskInfo.receive_time }}</span>
                    </div>
                    <div class="icon"></div>
                </div>
                <div class="record-item item-small">
                    <div class="txt-popover top">
                        <strong>首盘生产打料</strong>
                        <span>{{ taskInfo.product_time }}</span>
                    </div>
                    <div class="icon"></div>
                </div>
                <div class="record-item item-small">
                    <div class="txt-popover bottom">
                        <strong>首车打料完成</strong>
                        <span>{{ taskInfo.product_complete_time }}</span>
                    </div>
                    <div class="icon"></div>
                </div>
                <div class="record-item item-small">
                    <div class="txt-popover top">
                        <strong>首车出场</strong>
                        <span>{{ taskInfo.out_time }}</span>
                    </div>
                    <div class="icon"></div>
                </div>
                <div class="record-item item-small">
                    <div class="txt-popover bottom">
                        <strong>首车到达工地</strong>
                        <span>{{ taskInfo.arrival_time }}</span>
                    </div>
                    <div class="icon"></div>
                </div>
                <div class="record-item item-small">
                    <div class="txt-popover top">
                        <strong>首车卸料完成</strong>
                        <span>{{ taskInfo.unload_over_time }}</span>
                    </div>
                    <div class="icon"></div>
                </div>
                <div class="record-item record-time">
                    <div class="time-item" v-for="(item,index) in taskInfo.time_count" :key="index">
                        <div class="time-date">
                            {{ item.time }}
                        </div>
                        <span class="time-txt">{{ item.count }}车</span>
                    </div>
                </div>
                <div class="record-item item-big">
                    <div class="txt-popover top">
                        <strong>末车卸料完成</strong>
                        <span>{{ taskInfo.last_unload_over_time }}</span>
                    </div>
                    <div class="icon last">
                        <i class="iconfont icond"></i>
                    </div>
                </div>
                <div class="record-item item-big">
                    <div class="txt-popover bottom">
                        <strong>任务办结</strong>
                        <span>{{ taskInfo.task_complete_time }}</span>
                    </div>
                    <div class="icon last">
                        <i class="iconfont iconjihua"></i>
                    </div>
                </div>
                <i class="iconfont iconicon-up"></i>
            </div>
        </div>
        <div class="list-wrapper table-bg" style="height: calc(100% - 2.9rem)">
            <el-table
                :data="taskInfo.pcbInfos"
                style="width: 100%"
                class="table-list"
                height="100%"
            >
                <el-table-column
                    prop="pcb_number"
                    label="发货单号"
                    width="200"
                >
                    <template slot-scope="scope">
                        <span class="line-cell" @click="showDeliverInfo(scope.row)">
                            {{ scope.row.pcb_number }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="line_name"
                    label="生产机组"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="shch"
                    label="车号"
                >
                </el-table-column>
                <el-table-column
                    prop="plate_number"
                    label="车牌号"
                >
                </el-table-column>
                <el-table-column
                    prop="sjxm"
                    label="司机"
                >
                </el-table-column>
                <el-table-column
                    prop="deliver_time"
                    label="发货时间"
                    width="160"
                >
                </el-table-column>
                <el-table-column
                    prop="xdrw"
                    label="发货人"
                >
                </el-table-column>
                <el-table-column
                    prop="volume"
                    label="发货方量"
                >
                </el-table-column>
                <el-table-column
                    prop="filled_volume"
                    label="生产方量"
                    class-name="notice-cell"
                >
                </el-table-column>
                <el-table-column
                    prop="sign_volume"
                    label="签收方量"
                >
                </el-table-column>
                <el-table-column
                    prop="surplus_volume"
                    label="剩退方量"
                >
                </el-table-column>
                <el-table-column
                    prop="is_return"
                    label="是否工地退货"
                    width="120"
                >
                    <template slot-scope="scope">
                        {{ scope.row.is_return?'是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="is_forward"
                    label="是否转发"
                >
                    <template slot-scope="scope">
                        {{ scope.row.is_forward?'是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="is_turn"
                    label="是否转工地"
                >
                    <template slot-scope="scope">
                        {{ scope.row.is_turn?'是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="back_pcb_number"
                    label="关联退砼"
                >
                </el-table-column>
                <el-table-column
                    prop="turn_src_number"
                    label="关联转工地"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'production-delivery-info',
    data() {
        return {
            loading: false,
            task_number: '',
            // 数据信息
            taskInfo: {
                // 时间\次数
                time_count: [],
                // 派车单信息
                pcbInfos: [],
            },
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.task_number = this.extr.taskNumber;
        this.getInfo();
    },
    methods: {
        // 获取生产发货详情数据
        getInfo() {
            this.loading = true;
            this.$axios.get('/interfaceApi/production/concreteinspectrecord/concrete_pcb_info?task_number=' + this.task_number,)
                .then(res => {
                    if ((res.time_count || []).length > 0) {
                        res.time_count = this.formatTimeCount(res.time_count);
                    }
                    this.taskInfo = res || {};
                    this.loading = false;
                }).finally(() => {
                    this.loading = false;
                });
        },
        // 处理时间和车辆次数
        formatTimeCount(timeCount) {
            const times = [];
            const counts = [];
            if (!timeCount[0].includes(':')) {
                times.push('');
            }
            timeCount.forEach(item => {
                if (item.includes(':')) {
                    times.push(item);
                } else {
                    counts.push(item);
                }
            });
            const result = [];
            counts.forEach((count, index) => {
                result.push({
                    time: times[index],
                    count: count,
                });
            });
            return result;
        },

        // 显示发货信息
        showDeliverInfo(row) {
            this.$toast({
                width: '13rem',
                height: '8.5rem',
                type: 'eject',
                title: true,
                text: '发货信息',
                t_url: 'technicalQualityManagement/concreteQualityTrace/deliverInfo/productionDetail',
                extr: {
                    task_number: this.task_number,
                    row: row,
                },
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
.productionDeliveryInfo
    margin-top -0.02rem
    height 100%;
    background url("../images/bg.jpg") no-repeat center
    background-size 100% 100%
    .title-wrapper
        width 100%;
        display flex
        justify-content space-between
        padding 0.1rem;
        .title-item
            span
                padding 0.01rem 0.03rem;
                font-size 0.12rem;
                color #666666
            p
                font-weight bold
                font-size 0.18rem;
                color #333;
    .tab-wrapper
        margin 0 auto
        width 98%;
        display flex
        justify-content space-between;
        padding 0.05rem 0.1rem;
        background: #6582B0;
        box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(7, 47, 112, 0.5);
        .tab-item
            display flex
            flex-direction column
            justify-content center
            width: 1.3rem;
            height: 0.59rem;
            padding 0.1rem
            cursor: pointer;
            span
                font-size 0.12rem
                color #fff
            strong
                margin-top -0.04rem;
                font-weight normal
                font-size 0.3rem
                color #fff;
                font-family: Technology-Bold, Technology;
            &.bg1
                background: #0091FF;
    .record-line-wrapper
        padding 0 0.3rem
        margin-top 0.14rem
        width 100%;
        height 1.3rem;
        position relative
        display flex
        /*justify-content space-around*/
        align-items center
        background: #FFFFFF;
        box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.2);
        border: 1px solid #D7D7D7;
        overflow-x auto
        .record-item-list
            padding-right 0.5rem
            height 100%;
            position relative
            display flex
            &:after
                display block
                width 100%;
                height 0.02rem
                background #D7D7D7
                content ''
                position absolute
                top 50%;
                left 0;
            .record-item
                margin-left -0.1rem;
                position relative
                .txt-popover
                    position: absolute;
                    right 0
                    display flex
                    flex-direction column
                    align-items flex-end
                    white-space nowrap
                    &.top
                        top 0.06rem
                    &.bottom
                        bottom 0.06rem
                    strong
                        font-size 0.12rem;
                        font-weight normal
                        color #000;
                    span
                        font-size 0.12rem;
                        color #666666
                .icon:after
                    display block
                    content ''
                    width 0
                    height 0
                    border-style solid
                    border-width 0.04rem
                    border-color transparent transparent #0091FF transparent
                    position absolute
                    top -0.08rem;
                &.item-big
                    width 1.1rem
                    strong
                        font-weight 500
                        color #0091FF
                        font-size 0.14rem
                    .icon
                        width: 0.3rem;
                        height: 0.3rem;
                        background: #FFFFFF;
                        border: 1px solid #0091FF;
                        display flex
                        justify-content center
                        align-items center
                        border-radius 50%;
                        position: absolute;
                        top: 0.5rem;
                        right: 0;
                        z-index: 9;
                        .iconfont
                            border-radius 50%;
                            width: 0.24rem;
                            height: 0.24rem;
                            background: #0091FF;
                            font-size 0.14rem
                            color #fff
                            display flex
                            justify-content center
                            align-items center
                        &.last
                            right 0
                    &:nth-child(2n) .icon:after
                        border-color #0091FF transparent transparent transparent
                        top auto
                        bottom -0.08rem;
                &.item-small
                    width 1rem;
                    .icon
                        position absolute
                        top 0.565rem
                        right  0
                        width 0.16rem
                        height 0.16rem
                        border: 0.03rem solid #0091FF;
                        background: #FFFFFF;
                        border-radius 50%;
                        z-index 9
                    &:nth-child(2n-1) .icon:after
                        border-color transparent transparent #0091FF transparent
                        top -0.098rem
                        right: 0.01rem;
                    &:nth-child(2n) .icon:after
                        border-color #0091FF transparent transparent transparent
                        top auto
                        bottom -0.1rem;
                        right: 0.01rem;
            .iconicon-up
                position absolute
                top: 41%;
                right: -0.05rem;
                background none
                font-size 0.18rem
                color #d7d7d7
            .record-time
                margin 0 0.3rem
                position: relative;
                display flex
                justify-content center
                align-items center
                background #fff;
                z-index 9
                .time-item
                    width 0.7rem
                    height 0.2rem
                    margin-left -1px;
                    position relative
                    display flex
                    justify-content center
                    align-items center
                    font-size 0.12rem
                    color #FF7B22
                    border 1px solid #FF7B22
                .times-popover
                    width fit-content
                    position absolute
                    top -0.28rem
                    left 0
                    right 0
                    margin auto
                    background #FF7B22;
                    text-align center
                    padding 1px 0.07rem
                    color #fff;
                    &:after
                        display block
                        content ''
                        width 0.02rem
                        height 0.03rem
                        background #FF7B22
                        position absolute
                        bottom -0.03rem
                        left 0
                        right 0
                        margin auto;
                .time-date
                    position absolute
                    left -0.2rem
                    bottom -0.3rem;
                    color #666666
    .list-wrapper
        padding 0.1rem 0.2rem
        width 100%;
        overflow-x auto
        .line-cell
            color: #1B33FF;
            text-decoration underline
            cursor pointer
        >>> .notice-cell
            background #F2FAFF
            color #0091FF
            border: 1px solid #0091FF;
            &.is-leaf
                background #0091FF
                color #fff
</style>
